export const labels = {
  USERNAME: 'Username',
  PASSWORD: 'Password',
  ENTER_SERVER_URL: 'Enter your server URL',
  EXAMPLE_URL: 'http://my.server.com',
  EXAMPLE: 'Example',
  LOG_IN: 'Log in',
  SUBMIT: 'Submit',
};

export const defaultDevServers = [
  { name: 'Thrasys 1', url: 'https://devthr1.thrasys.com/Synergy' },
  { name: 'Thrasys 2', url: 'https://devthr2.thrasys.com/Synergy' },
  { name: 'AC3', url: 'https://devac31.thrasys.com/Synergy' },
  { name: 'LA Care', url: 'https://devlac3.thrasys.com/Synergy' },
  { name: 'Empirx', url: 'https://devemp1.thrasys.com/Synergy' },
  { name: 'Localhost', url: 'http://localhost:8080/Synergy' },
];
